import React from 'react';
import {
  Container,
  Paper,
  Typography,
  SvgIcon,
  TextField,
  Button,
  InputAdornment,
} from '@mui/material';
import FilterHdrIcon from '@mui/icons-material/FilterHdr';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import './LoginPage.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { APP_BASE_PATH } from 'Host/endpoint';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import LoginIcon from '@mui/icons-material/Login';
const Login = () => {
  // const dispatch = useDispatch()
  // const headerStyle = {
  //   color: '#292bc4',
  //   fontFamily: 'serif',
  // };

  // const [value, setValue] = React.useState({
  //   name: '',
  //   password: '',
  // });

  // const [showPassword, setShowPassword] = React.useState(false);

  // const navigate = useNavigate();

  // axios.defaults.withCredentials = true;

  // const handlesubmit = (event) => {
  //   event.preventDefault();
  //   axios
  //     .post(`${APP_BASE_PATH}/login`, value)
  //     .then((res) => {
  //       console.log(res.data); // Log the response
  //       if (res.data.Status === 'Success') {
  //         console.log('Login successful');
  //         dispatch({type:'login'})
  //         console.log(res.data.type)
  //         // Set the authentication token in cookies
  //         document.cookie = `token=${res.data.token}; path=/`;
  //         navigate('/dashboard');
  //         localStorage.setItem('type',res.data.type)
  //         console.log('Navigation triggered');
  //       } else {
  //         alert(res.data.Error);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error); // Log any errors
  //     });
  // };
  const dispatch = useDispatch();
  const headerStyle = {
    color: '#292bc4',
    fontFamily: 'serif',
  };
  
  const [value, setValue] = React.useState({
    name: '',
    password: '',
  });
  
  const [showPassword, setShowPassword] = React.useState(false);
  
  const navigate = useNavigate();
  
  // Set the default options for the Fetch API
  const fetchOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include', // To include cookies
  };
  
  const handlesubmit = async (event) => {
    event.preventDefault();
  
    try {
      const response = await fetch(`${APP_BASE_PATH}/login`, {
        ...fetchOptions,
        body: JSON.stringify(value),
      });
  
      if (response.ok) {
        const data = await response.json();
  
        if (data.Status === 'Success') {
          console.log('Login successful');
          dispatch({ type: 'login' });
          console.log(data.type,data.id);
          // Set the authentication token in cookies
          document.cookie = `token=${data.token}; path=/`;
          navigate('/dashboard');
          localStorage.setItem('userData', JSON.stringify({ type: data.type, id: data.id }));

          console.log('Navigation triggered');
          
        } else {
          const errorData = data; // Store the data for error handling
          // alert(errorData.Error);
          Swal.fire({
            title: "Credentials Does Not Match!",
            icon: "error",
            iconHtml: "",
            confirmButtonText: "OK",
            animation: "true",
            confirmButtonColor: "red",
          });
        }
      } else {
        console.error('Failed to fetch');
      }
    } catch (error) {
      console.error(error); 
      Swal.fire({
        title: "Something went wrong!",
        icon: "error",
        iconHtml: "",
        confirmButtonText: "OK",
        animation: "true",
        confirmButtonColor: "red",
      });// Log any errors
    }
  };
  
  // Usage of "handleSubmit" in your component
  // <form onSubmit={handleSubmit}>
  //   {/* Form input fields */}
  // </form>
  

// Usage of "handlesubmit" in your component
// <form onSubmit={handleSubmit}>
//   {/* Form input fields */}
// </form>


  return (
    <div className="login-container">
      <Container maxWidth="xs">
        <Paper
          elevation={3}
          className="login-paper"
          style={{ borderRadius: '15px', marginLeft: '26rem' }}
        >
          <Typography variant="h3" align="center" style={headerStyle}>
            TransfocoM
          </Typography>
          <div className="logo-container">
          
            <SvgIcon
              component={FilterHdrIcon}
              className="mui-logo"
              fontSize="inherit"
              style={{ marginRight: 8, fontSize: '7rem' }}
            />
          </div>
          
          <Typography variant="h5" align="center" style={{ color: 'white' }}>
            LOG IN
          </Typography>
          <form>
            <div className="input-container">
              <TextField
                fullWidth
                label={
                  <SvgIcon component={PersonIcon} fontSize="small" className="input-icon" />
                }
                placeholder="Username"
                variant="outlined"
                margin="normal"
                name="name"
                onChange={(e) => setValue({ ...value, name: e.target.value })}
                InputProps={{
                  style: { color: 'white' },
                }}
              />
            </div>
            <div className="input-container">
              <TextField
                fullWidth
                label={
                  <SvgIcon component={LockIcon} fontSize="small" className="input-icon" />
                }
                placeholder="Password"
                variant="outlined"
                margin="normal"
                type={showPassword ? 'text' : 'password'}
                name="password"
                onChange={(e) => setValue({ ...value, password: e.target.value })}
                InputProps={{
                  style: { color: 'white' },
                  endAdornment: (
                    <InputAdornment position="end">
                      {showPassword ? (
                        <VisibilityOffIcon
                          onClick={() => setShowPassword(false)}
                          style={{ cursor: 'pointer', color: 'white' }}
                        />
                      ) : (
                        <VisibilityIcon
                          onClick={() => setShowPassword(true)}
                          style={{ cursor: 'pointer', color: 'white' }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <Button
              variant="contained"
              onClick={handlesubmit}
              className="gradient-button"
              style={{ borderRadius: '20px' }}
            >
              Login
            </Button>
          </form>
        </Paper>
      </Container>
    </div>
  );
};

export default Login;
