const formatDate = (date) => {
  if (date) {
    if (date?.lastIndexOf?.("/") === 5) {
      const [dd, mm, yyyy] = date.split("/");
      date = `${yyyy}-${mm}-${dd}`;
    }
    // eslint-disable-next-line eqeqeq
    if (new Date(date) == "Invalid Date") {
      return date;
    }
    return new Date(date).toLocaleDateString("en-GB");
  }
  return "";
};

const getDate = (date) => {
  return new Date(date).toLocaleDateString("en-GB");
};

export { formatDate, getDate };
