import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CustomRoutes from "./component/route/routes";
import Login from "component/LoginPages/Login";
import { useDispatch, useSelector } from "react-redux";
import ProtectedRoute from "component/route/ProtectedRoute";
import store from "component/Store";



function App() {
  const dispatch = useDispatch()
  const {isAuthenticated}= useSelector(state => state.root)
  const token = document.cookie.split('; ').find(row => row.startsWith('token='));

if (token) {
  store.dispatch({type:'login'}); // Dispatch login action if token is present
}
  return (
    <div className="App">
      <Router basename={process.env.PUBLIC_URL}>
        <Routes>
        <Route index element={<Login/>} />
          <Route path="/*" element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <CustomRoutes />
          </ProtectedRoute>
          } />
        </Routes>
      </Router>
    
    </div>
  );
}

export default App;
// 
